import React from 'react';
import Helmet from 'react-helmet';

import { Stack } from '@nib/layout';

import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

import Section from '@nib-components/section';
import Layout from '../../../components/Layout';
import metrics from '../../../metrics';

const BlogNotFound = (): JSX.Element => {
  return (
    <Layout>
      <div>
        <Helmet>
          <title>Blog Not Found | nib</title>
        </Helmet>
        <Section>
          <Stack space={4}>
            <Heading size={{ sm: 2, lg: 1 }} component="h1">
              No Blog Found
            </Heading>
            <Copy measure={false}>
              Return to the <Link href="/free-resources">Free resources</Link> page
            </Copy>
          </Stack>
        </Section>
      </div>
    </Layout>
  );
};

export default metrics({ pageName: 'blog-not-found' })(BlogNotFound);
